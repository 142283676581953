import { render, staticRenderFns } from "./SpellCreator.vue?vue&type=template&id=d69eef2a"
import script from "./SpellCreator.vue?vue&type=script&lang=js"
export * from "./SpellCreator.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SpellCreator.vue?vue&type=custom&index=0&blockType=styles&lang=scss&scoped=true"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn});
